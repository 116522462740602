import { template as template_10e4081da20d4149a2ff13cf25a4d648 } from "@ember/template-compiler";
const FKControlMenuContainer = template_10e4081da20d4149a2ff13cf25a4d648(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
