import { template as template_114bd9f39c3e4672b6e46f1a322d831c } from "@ember/template-compiler";
const WelcomeHeader = template_114bd9f39c3e4672b6e46f1a322d831c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
