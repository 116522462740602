import { template as template_71e1c521c60e4c93ac2fd790a34b587a } from "@ember/template-compiler";
const FKText = template_71e1c521c60e4c93ac2fd790a34b587a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
